import { localStorage } from "@reactizer/session";
import { checkInvite } from "@utilities/web/checkInvite";
import { idTokenName } from "@vendors/auth0/constants/cookies";
import {
  authorizeWithGoogle,
  Options as GoogleAuthOptions,
} from "@vendors/auth0/web/authorizeWithGoogle";
import { crossOriginFallback } from "@vendors/auth0/web/crossOriginFallback";
import { loginWithCode } from "@vendors/auth0/web/loginWithCode";
import { loginWithPassword } from "@vendors/auth0/web/loginWithPassword";
import { logout } from "@vendors/auth0/web/logout";
import { sendPasswordChange } from "@vendors/auth0/web/sendPasswordChange";
import { sendPasswordlessCode } from "@vendors/auth0/web/sendPasswordlessCode";
import {
  Options as SignupWithPasswordOptions,
  signupWithPassword,
} from "@vendors/auth0/web/signupWithPassword";
import { CrossOriginLoginOptions } from "auth0-js";
import { getCookie } from "cookies-next";
import qs from "query-string";

const audience = process.env.NEXT_PUBLIC_AUTH0_API_AUDIENCE || "";
const realm = process.env.NEXT_PUBLIC_AUTH0_CONNECTION_DB || "";
const redirectUri = process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI || "";
const baseDomain = process.env.NEXT_PUBLIC_BASE_DOMAIN || "";

export function useAuth() {
  const getInviteEmail = () => {
    return localStorage.load("inviteEmail");
  };

  const confirmInvite = async (email: string, inviteCode: string) => {
    const inviteId = await checkInvite({ email, inviteCode });
    if (!inviteId) {
      throw new Error("Invalid Invite");
    }

    localStorage.save("inviteEmail", email);

    return inviteId;
  };

  // Signup with Password
  const signup = async (options: SignupWithPasswordOptions) => {
    const result = await signupWithPassword(options);
    return result;
  };

  // Authorize with Google
  const googleAuth = async (options: GoogleAuthOptions) => {
    authorizeWithGoogle(options);
  };

  // Auth0 Code Callback Fallback
  const authCodeCallbackFallback = async () => {
    crossOriginFallback();
  };

  // Login
  const authLogin = async (
    options: CrossOriginLoginOptions,
    returnTo?: string,
  ) => {
    const params = {
      responseMode: "query",
      responseType: "code",
      realm,
      redirectUri: `${redirectUri}?${qs.stringify({ returnTo })}`,
      audience,
      ...options,
    };

    const result = await loginWithPassword(params);
    return result;
  };

  // Logout
  const authLogout = async (returnToPath: string = "") => {
    const returnTo = `${baseDomain}${returnToPath}`;
    logout({ returnTo });
  };

  // Send Code
  const sendAuthCode = async (email: string, returnTo: string = "") => {
    return sendPasswordlessCode(email, {
      redirectUri: `${redirectUri}?${qs.stringify({ returnTo })}`,
    });
  };

  // Login with Code
  const authWithCode = async (
    email: string,
    code: string,
    returnTo: string = "",
  ) => {
    return loginWithCode(email, code, {
      redirectUri: `${redirectUri}?${qs.stringify({ returnTo })}`,
    });
  };

  // Change Password
  const changePassword = async (email: string) => {
    return sendPasswordChange(email);
  };

  const isAuth = !!getCookie(idTokenName);

  //
  return {
    authCodeCallbackFallback,
    authLogin,
    authLogout,
    authWithCode,
    confirmInvite,
    getInviteEmail,
    googleAuth,
    sendAuthCode,
    changePassword,
    signup,
    isAuth,
  };
}

import initClient from "@vendors/auth0/web/client";
import { DbSignUpOptions, Auth0Error, Auth0Result } from "auth0-js";

const connection = process.env.NEXT_PUBLIC_AUTH0_CONNECTION_DB || "";

export interface Options {
  email: string;
  password: string;
  returnTo?: string;
}

export async function signupWithPassword(
  options: Options,
): Promise<Auth0Result> {
  const { email, password, returnTo } = options;

  return new Promise((resolve, reject) => {
    const signupData: DbSignUpOptions = {
      connection,
      email,
      password,
    };

    const callback = (error: Auth0Error, result: Auth0Result) => {
      return !!error ? reject(error) : resolve(result);
    };

    const client = initClient();
    client.signupAndAuthorize(signupData, callback);
  });
}

import initClient from "@vendors/auth0/web/client";
import { DbSignUpOptions, Auth0Error, Auth0Result } from "auth0-js";
import { ApiSetCookiesHelper } from "@utilities/api/helpers/setCookies";

const baseUrl = process.env.NEXT_PUBLIC_AUTH0_BASE_URL || "";

export interface Options {
  returnTo?: string;
}

export function logout(options?: Options) {
  const { returnTo = `${baseUrl}` } = options;

  const client = initClient();
  client.logout({ returnTo });
}

import initClient from "@vendors/auth0/web/client";

export async function crossOriginFallback() {
  return new Promise<void>((resolve) => {
    const client = initClient();

    client.crossOriginVerification();
    resolve();
  });
}

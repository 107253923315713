import initClient from "@vendors/auth0/web/client";
import { Auth0Error, Auth0Result } from "auth0-js";

const connection = process.env.NEXT_PUBLIC_AUTH0_CONNECTION_DB || "";

export async function sendPasswordChange(email: string): Promise<Auth0Result> {
  return new Promise((resolve, reject) => {
    const callback = (error: Auth0Error, result: Auth0Result) => {
      return !!error ? reject(error) : resolve(result);
    };

    const client = initClient();
    client.changePassword({ connection, email }, callback);
  });
}

import auth0 from "auth0-js";

const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || "";
const clientID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || "";
const scope = process.env.NEXT_PUBLIC_AUTH0_SCOPE || "";
const baseUrl = process.env.NEXT_PUBLIC_AUTH0_BASE_URL || "";

function initClient(options: Partial<auth0.AuthOptions> = {}) {
  return new auth0.WebAuth({ domain, clientID, scope, ...options });
}

export default initClient;

export const allowedLogoutUrl = {
  signup: `${baseUrl}/signup/account`,
  home: `${baseUrl}`,
};

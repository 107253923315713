import initClient from "@vendors/auth0/web/client";
import { Auth0Error, Auth0Result, AuthOptions } from "auth0-js";

export async function loginWithCode(
  email: string,
  verificationCode: string,
  options?: Partial<AuthOptions>,
): Promise<Auth0Result> {
  return new Promise((resolve, reject) => {
    const callback = (error: Auth0Error, result: Auth0Result) => {
      return !!error ? reject(error) : resolve(result);
    };

    const client = initClient({
      responseType: "token id_token",
      ...options,
    });
    client.passwordlessLogin(
      { connection: "email", email, verificationCode },
      callback,
    );
  });
}

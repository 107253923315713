import axios from "axios";

const checkInviteEndpoint = "/api/v1/user/invite";

interface InviteData {
  email: string;
  inviteCode: string;
}

export async function checkInvite(data: InviteData) {
  const response = await axios.post(checkInviteEndpoint, data);
  const responseData = response.data;

  return responseData.data;
}

import initClient from "@vendors/auth0/web/client";
import { AuthorizeOptions } from "auth0-js";
import qs from "query-string";

const connection = process.env.NEXT_PUBLIC_AUTH0_CONNECTION_GA || "";

const redirectUri = process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI || "";
// const responseType = process.env.NEXT_PUBLIC_AUTH0_RESPONSE_TYPE || "";
const scope = process.env.NEXT_PUBLIC_AUTH0_SCOPE || "";
const audience = process.env.NEXT_PUBLIC_AUTH0_API_AUDIENCE || "";

export interface Options {
  mode?: "login" | "signUp";
  returnTo?: string;
  fromPath?: string;
}

export function authorizeWithGoogle(options: Options) {
  const { mode, returnTo, fromPath } = options;

  const authorizeOptions: AuthorizeOptions = {
    connection,
    mode,
    redirectUri: `${redirectUri}?${qs.stringify({ returnTo })}`,
    responseMode: "query",
    responseType: "code",
    appState: { returnTo, fromPath },
    audience,
    scope,
  };

  const client = initClient();
  client.authorize(authorizeOptions);
}
